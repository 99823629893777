import React from "react";
import Layout from "../components/layout"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'react-bootstrap';
import PostFishingPage from "../components/PostFishingPage";
import styled from "styled-components";
import Seo from "../components/Seo"

const BlogRelate = styled.div`
    border-bottom: 3px solid #720000;
    margin-bottom: 15px;
    h3 {
        font-size: 28px;
        margin-bottom: 15px;
    }
`

const FishingPage = ({pageContext}) => {
    const getphoto = pageContext.featuredImage === null 
        ? null 
        : pageContext.featuredImage.node.localFile.childImageSharp
    const image = getImage(getphoto)
    const imageSeo = pageContext.featuredImage.node.localFile.publicURL
    return (
    <Layout>
        <Seo 
            title={pageContext.seo.title}
            description={pageContext.seo.metaDesc}
            image={"https://kickoffx999.com" + imageSeo}
            url={"https://kickoffx999.com" + pageContext.seo.canonical}
            canonical={"https://kickoffx999.com" + pageContext.seo.canonical}
        />
        <StaticImage src="../images/hero-banner.png" alt="hero-banner" />
        <Container className="py-5">
            <Row>
                <Col md={12}>
                    <BlogRelate>
                        <h3>เกมส์ตกปลา <span>สำหรับลูกค้าเรา</span></h3>
                    </BlogRelate>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/bole-fish.jpg" alt="bole-fish-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/ds-fish.jpg" alt="ds-fish-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/cq9-fish.jpg" alt="cq9-fish-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/jdb-fish.jpg" alt="jdb-fish-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/joker-fish.jpg" alt="joker-fish-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/km.jpg" alt="km-01" />
                </Col>
                <Col xs={6} md={3} className="pb-3">
                    <StaticImage className="img-radius" src="../images/fishing/v2022/sm-fish.jpg" alt="sm-fish-01" />
                </Col>
            </Row>
        </Container>
        <Container className="pb-5">
            <h1 className="pb-3" dangerouslySetInnerHTML={{__html: pageContext.title}} />
                <GatsbyImage image={image} />
            <div className="pt-3" dangerouslySetInnerHTML={{__html: pageContext.content}} />
        </Container>
        <Container>
            <Row>
                <Col md={12}>
                    <BlogRelate>
                        <h3>บทความที่เกี่ยวข้อง</h3>
                    </BlogRelate>
                </Col>
            </Row>
            <PostFishingPage slug={pageContext.slug} />
        </Container>
    </Layout>
)};

export default FishingPage;